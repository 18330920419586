enum WorkflowExecutionStrategy {
  /// <summary>
  /// Provides a possibility in the SystemInsight UI to execute the Workflow Once on an Event
  /// </summary>
  ManualExecutionOnce = 'ManualExecutionOnce',

  /// <summary>
  /// Provides a possibility in the SystemInsight UI to execute the Workflow multiple times
  /// </summary>
  ManualExecutionMultipleTimes = 'ManualExecutionMultipleTimes',

  /// <summary>
  /// Automatically triggers the workflow once as soon as such an event arrives
  /// </summary>
  AutomaticExecution = 'AutomaticExecution',
}

export default WorkflowExecutionStrategy;
