
import { Options, Vue } from 'vue-class-component';
import ISystemInsightSettings from '@/models/SystemInsight/ISystemInsightSettings';
import { LogLevel } from '@/models/SystemInsight/LogLevel';
import { LifetimeUnit } from '@/models/SystemInsight/LifetimeUnit';
import DxSwitch from 'devextreme-vue/switch';
import DxSelectBox from 'devextreme-vue/select-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import SystemInsightSettingsService from '@/services/SystemInsightSettingsService';
import { Container } from 'typescript-ioc';
import { AlertService, IAlert, IToolkitButton, DipInput, ToolkitButton } from 'rey-web-toolkit';
import { DxPopup } from 'devextreme-vue/popup';
import { Watch, Prop } from 'vue-property-decorator';

declare type LifetimeUnitDropdownEntry = { displayName: string; value: LifetimeUnit };
declare type LogLevelDropdownEntry = { displayName: string; value: LogLevel };

@Options({
  components: {
    DxSwitch,
    DxSelectBox,
    DxNumberBox,
    DxButton,
    DxPopup,
    DipInput,
    ToolkitButton,
  },
})
export default class SystemInsightSettingsEditDialog extends Vue {
  private service = Container.get(SystemInsightSettingsService);
  private alertService = Container.get(AlertService);

  @Prop({})
  public isVisible = false;

  public emits = ['errorWhileOpening', 'savedSuccessfully', 'dialogCancelled'];

  public systemInsightSettings: ISystemInsightSettings = {
    isSystemInsightActive: true,
    minLogLevel: LogLevel.Trace,
    lifetimeValue: 0,
    lifetimeUnit: LifetimeUnit.Hours,
  };

  public submitDialogAction: IToolkitButton = {
    name: 'Speichern',
    group: 'SystemInsightSettingsEditDialog',
    icon: 'fas fa-save',
    iconPosition: 'left',
    execute: () => this.submit(),
  };

  public cancelDialogAction: IToolkitButton = {
    name: 'Abbrechen',
    group: 'SystemInsightSettingsEditDialog',
    icon: 'fas fa-times',
    iconPosition: 'left',
    execute: () => this.cancel(),
  };

  @Watch('isVisible')
  private async open(newValue: boolean) {
    if (!newValue) {
      return;
    }

    const result = await this.service.get();
    if (result.error) {
      this.$emit('errorWhileOpening');
    }
    if (result.data) {
      this.systemInsightSettings = result.data;
    }
  }

  private async submit() {
    const result = await this.service.update(this.systemInsightSettings);
    if (result.error) {
      this.alertService.add({ type: 'danger', message: 'Die Einstellungen konnten nicht gespeichert werden.', autoCloseInMs: 5000 });
    } else {
      this.$emit('savedSuccessfully');
    }
  }

  private cancel() {
    this.$emit('dialogCancelled');
  }

  configurableLogLevels = [
    { displayName: 'Trace', value: LogLevel.Trace },
    { displayName: 'Debug', value: LogLevel.Debug },
    { displayName: 'Information', value: LogLevel.Information },
    { displayName: 'Warnung', value: LogLevel.Warning },
    { displayName: 'Fehler', value: LogLevel.Error },
    { displayName: 'Kritischer Fehler', value: LogLevel.Critical },
    { displayName: 'Kein', value: LogLevel.None },
  ] as LogLevelDropdownEntry[];

  configurableLifetimeUnits = [
    { displayName: 'Stunden', value: LifetimeUnit.Hours },
    { displayName: 'Tage', value: LifetimeUnit.Days },
    { displayName: 'Wochen', value: LifetimeUnit.Weeks },
  ] as LifetimeUnitDropdownEntry[];
}
