import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_SmartTableV2 = _resolveComponent("SmartTableV2")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SmartTableV2, {
      tableId: "eventConfigurationTable",
      dataStore: _ctx.dataStore,
      readonly: false,
      hideFunctionColumn: "true"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxColumn, {
          "data-field": "key",
          caption: "Key",
          visible: false,
          "allow-editing": false,
          "data-type": "string"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "systemInsightEventKey",
          "allow-editing": true,
          caption: "Event"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxLookup, {
              "data-source": _ctx.systemInsightEvents,
              "value-expr": "key",
              "display-expr": 
            (d) => {
              return d.eventId + ': ' + d.name;
            }
          
            }, null, 8, ["data-source", "display-expr"])
          ]),
          _: 1
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "workflowDefinitionKey",
          "allow-editing": true,
          caption: "Workflow"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxLookup, {
              "data-source": _ctx.workflowDefinitions,
              "value-expr": "key",
              "display-expr": "title"
            }, null, 8, ["data-source"])
          ]),
          _: 1
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "executionStrategy",
          "allow-editing": true,
          caption: "Ausführungszeitpunkt"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxLookup, {
              "data-source": _ctx.executionStrategies,
              "value-expr": "key",
              "display-expr": "name"
            }, null, 8, ["data-source"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["dataStore"])
  ]))
}