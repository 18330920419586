
import { Options, Vue } from 'vue-class-component';
import { DxColumn, DxLookup, DxMasterDetail } from 'devextreme-vue/data-grid';
import { DipODataStore, GenericErrorHandler, SmartTableV2 } from 'rey-web-toolkit';
import SystemInsightSettingsEditDialog from './SystemInsightSettingsEditDialog.vue';
import WorkflowExecutionStrategy from '@/models/SystemInsight/WorkflowExecutionStrategy';
import WorkflowTypeEnum from '@/models/WorkflowTypeEnum';

@Options({
  components: {
    DxColumn,
    DxLookup,
    DxMasterDetail,
    SmartTableV2,
    SystemInsightSettingsEditDialog,
  },
})
export default class WorkerWorkplace extends Vue {
  public dataStore: any | undefined = undefined;
  public isSystemInsightSettingsEditModeActive = false;
  public isWorkInProgress = false;

  public executionStrategies = [
    { key: WorkflowExecutionStrategy.ManualExecutionOnce, name: 'Einmalige manuelle Ausführung' },
    { key: WorkflowExecutionStrategy.ManualExecutionMultipleTimes, name: 'Mehrfache manuell Ausführung' },
    { key: WorkflowExecutionStrategy.AutomaticExecution, name: 'Automatische Ausführung' },
  ];
  public workflowDefinitions: any | undefined = undefined;
  public systemInsightEvents: any | undefined = undefined;

  public async beforeCreate() {
    document.title = 'SystemInsight';

    this.dataStore = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/SystemInsightEventWorkflow`,
          key: 'key',
          keyType: 'Guid',
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: ['systemInsightEventKey', 'workflowDefinitionKey'],
    };
    this.workflowDefinitions = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/WorkflowDefinition`,
          key: 'key',
          keyType: 'Guid',
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: 'title',
      filter: ['workflowType', '=', WorkflowTypeEnum.BackgroundForLogs],
    };
    this.systemInsightEvents = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/SystemInsightEvent`,
          key: 'key',
          keyType: 'Guid',
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: 'eventId',
    };
  }
}
