import ISystemInsightSettings from '@/models/SystemInsight/ISystemInsightSettings';
import axios from 'axios';
import { IRFC7807ErrorResponse } from 'rey-web-toolkit';

type GetResult = {
  data?: ISystemInsightSettings;
  error?: IRFC7807ErrorResponse;
};

type UpdateResult = {
  error?: IRFC7807ErrorResponse;
};

export default class SystemInsightSettingsService {
  public async get(): Promise<GetResult> {
    return axios
      .get<ISystemInsightSettings, GetResult>('api/SystemInsightSettings')
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  }

  public async update(settings: ISystemInsightSettings): Promise<UpdateResult> {
    return axios
      .put<ISystemInsightSettings, UpdateResult>('api/SystemInsightSettings', settings)
      .then(() => {
        return {};
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  }
}
